import * as React from 'react';
import styled from 'styled-components';
import { Header } from '@/components/header';
import { Pages } from '@/webapi/pages';
import { VSpace } from '@/components/spacing';
import { nav } from '@/utils/browser';

export const PAGE_LAYOUT_ID = `page-layout-wrapper`;

export interface PageLayoutProps {
  headerControls?: any;
  hideBackButton: boolean;
  backButtonText?: string;
  backButtonPage?: Pages;

  heading?: string;
  description?: string;
  children: any;
}

export function PageLayout({
  headerControls,
  hideBackButton,
  backButtonText,
  backButtonPage,
  children,
  heading,
  description,
}: PageLayoutProps) {
  return (
    <Wrapper id={PAGE_LAYOUT_ID}>
      <Header headerControls={headerControls} hideBackToLive />
      <Content>
        {!hideBackButton && (
          <BackNavigation
            id="back-btn"
            page={backButtonPage || Pages.DASHBOARD}
          >
            {backButtonText || `< Back to Homepage`}
          </BackNavigation>
        )}

        {heading && (
          <>
            <VSpace value={3} />
            <h1>{heading}</h1>
          </>
        )}
        {description && (
          <>
            <VSpace value={2} />
            <h2>{description}</h2>
          </>
        )}
        <VSpace value={3} />
        {children}
        <VSpace value={3} />
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  background: rgb(248, 249, 251);

  overflow-y: scroll;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  padding-bottom: 2rem;

  font-family: Inter, serif;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  align-items: center;

  h1 {
    user-select: none;
    color: #000000;
    font-family: Eesti, serif;
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.48px;
    margin: 0;
  }

  h2 {
    user-select: none;
    color: #000000;
    font-family: 'JetBrains Mono', serif;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0.21px;
    margin: 0;
  }
`;

export interface BackNavigation {
  id?: string;
  children: any;
  page: Pages;
}

export function BackNavigation({ id, children, page }: BackNavigation) {
  const onClick = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    await nav(page);
  };

  return (
    <BackNavigationText onClick={onClick} id={id}>
      {children}
    </BackNavigationText>
  );
}

const BackNavigationText = styled.span`
  color: #a6afb8;
  font-weight: bold;
  letter-spacing: 0.23px;
  font-size: 1.2rem;
  font-family: Inter, serif;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s linear;
  :hover {
    color: #4f5359;
  }
`;
