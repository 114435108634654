import React from 'react';
import styled from 'styled-components';
import { ImageUpload } from '@/features/my-account/ImageUpload';
import { FormInput } from '@/components/floating-label-text-input';
import { useUpdatePersonalInfo } from '@/features/my-account/useUpdatePersonalInfo';
import { BigButton } from '@/components/big-button';

const Container = styled.div`
  width: 100%;
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FormStyled = styled.form`
  width: 100%;
  display: grid;
  grid-template-areas: 'a1 a2' 'a3 a3' 'a4 a4' 'a5 a5';
  grid-row-gap: 0;
  grid-column-gap: 12px;
  padding: 0 2rem;
`;

export function DetailsForm() {
  const {
    ctx,
    register,
    onSubmit,
    errors,
    reset,
    loading,
    image,
    setImage,
    hasChanges,
  } = useUpdatePersonalInfo();

  return (
    <Container>
      <ImageUpload ctx={ctx} setImage={setImage} image={image} />
      <FormStyled onSubmit={onSubmit}>
        <FormInput
          errors={errors}
          disabled={loading}
          defaultValue={ctx?.account?.store?.firstName || ``}
          name="firstName"
          label="First Name"
          register={register}
          style={{ gridArea: `a1` }}
        />
        <FormInput
          errors={errors}
          disabled={loading}
          name="lastName"
          label="Last Name"
          defaultValue={ctx?.account?.store?.lastName || ``}
          register={register}
          style={{ gridArea: `a2` }}
        />
        <FormInput
          errors={errors}
          disabled
          name="email"
          label="Email"
          defaultValue={ctx?.account?.store?.email || ``}
          register={register}
          type="email"
          style={{ gridArea: `a3` }}
        />
        <FormInput
          errors={errors}
          disabled={loading}
          name="phone"
          label="Phone"
          type="tel"
          invalidMessage="please enter a valid international phone (+11234456789)"
          pattern={
            /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/i
          }
          defaultValue={ctx?.account?.store?.phone || `+11234456789`}
          register={register}
          style={{ gridArea: `a4` }}
        />
        <BtnsWrapper style={{ gridArea: `a5`, opacity: hasChanges ? 1 : 0 }}>
          <BigButton size="large" disabled={!hasChanges} type="submit">
            Save
          </BigButton>
          <BigButton
            size="large"
            disabled={!hasChanges}
            type="button"
            onClick={() => {
              setImage(ctx?.account?.store?.profilePic || ``);
              reset();
            }}
            border="2px solid #C8CACB"
            background="white"
            color="#97A0A8"
            noShadow
            noTransform
          >
            Cancel
          </BigButton>
        </BtnsWrapper>
      </FormStyled>
    </Container>
  );
}

const BtnsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;

  button {
    margin: 1rem;
  }
`;
