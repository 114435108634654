import React, { FC } from 'react';
import styled from 'styled-components';
import { InnerHeader } from './header';
import { DetailsForm } from '@/features/my-account/DetailsForm';
import { PageLayout } from '@/components/page-layout';

const MainContent = styled.div`
  margin: 2rem auto;
  width: 915px;
  border-radius: 10px;
  border: 1px solid #f1f3f4;
  background-color: white;
  box-shadow: 0 1px 4px 0 rgba(68, 72, 80, 0.08);
  text-align: center;
  padding: 3rem 3rem 0 3rem;
  font-family: Inter, serif;

  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const MyAccountPage: FC = () => (
  <PageLayout hideBackButton={false} heading="My Account">
    <MainContent>
      <InnerHeader />
      <DetailsForm />
    </MainContent>
  </PageLayout>
);
