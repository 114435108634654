import React, { useEffect } from 'react';
import styled from 'styled-components';
import Resizer from 'react-image-file-resizer';
import { AiFillPlusCircle } from 'react-icons/ai';
import { firstLetters } from '@/components/header';
import { AccountContextProps } from '@/features/account-context';
import { nameCase } from '@/utils/string-utils';
import { Flexbox } from '@/components/flex';

export function ImageUpload({
  ctx,
  setImage,
  image,
}: {
  ctx: AccountContextProps;
  setImage: (x: any) => void;
  image: string;
}) {
  const store = ctx?.account?.store;
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const { current } = uploadedImage;
  useEffect(() => {
    setImage(image || store?.profilePic);
  }, []);
  useEffect(() => {
    if (current) {
      current.src = image || store?.profilePic;
    }
  }, [image, current]);
  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      Resizer.imageFileResizer(
        file,
        80,
        80,
        `JPEG`,
        20,
        0,
        (uri) => {
          setImage(uri);
        },
        `base64`,
        80,
        80,
      );
    }
  };
  const hasImage = image?.length > 0;
  return (
    <UploadWrapper onClick={() => imageUploader.current.click()}>
      <Flexbox align="center" justify="center">
        <ImageUploadStyle hasImage={hasImage}>
          {firstLetters(ctx)}
          <BluePlus>
            <AiFillPlusCircle />
          </BluePlus>
        </ImageUploadStyle>
        <PicContainer hasImage={hasImage}>
          <img alt="ppic" ref={uploadedImage} />
        </PicContainer>
        <p style={{ margin: `10px 0 0 0`, width: `160px` }}>
          {nameCase(store?.firstName)} {nameCase(store?.lastName)}
        </p>

        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          ref={imageUploader}
          style={{
            display: `none`,
          }}
        />
      </Flexbox>
    </UploadWrapper>
  );
}

const PicContainer = styled.div`
  width: 70px;
  height: 70px;
  display: ${({ hasImage }) => (hasImage ? `block` : `none`)};

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
`;
const UploadWrapper = styled.button`
  position: relative;
  background: none;
  margin: 10px 0 7px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #f1f2f3;
  padding: 0 2rem;
  height: 190px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    border: 1px solid grey;
  }

  &:active {
    cursor: default;
    opacity: 1;
  }

  div {
    p {
      text-align: center;
      width: 100%;
      user-select: none;
      font-size: 1.4rem;
      font-weight: 600;
    }
  }
`;

const ImageUploadStyle = styled.div`
  display: ${({ hasImage }) => (hasImage ? `none` : `flex`)};

  position: relative;
  margin-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  padding: 2rem;
  text-align: center;
  background-color: #f1f2f3;
  user-select: none;
  font-weight: 600;
  letter-spacing: 1.1px;
  border: 1px solid #dedede;
`;

const BluePlus = styled.div`
  color: #36a6ff;
  right: -6px;
  top: 36px;
  position: absolute;
`;
