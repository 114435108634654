import styled from 'styled-components';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import React from 'react';

interface Props {
  err: boolean;
  disabled: boolean;
}

export const FloatingInput = styled.div`
  --color__accent: #aaaaaa;
  --input__text--color: #0f0f0f;
  --field__background--hover: #e9e9e9;
  --input__border: rgba(0, 0, 0, 0.42);
  --input__border--hover: rgba(0, 0, 0, 0.62);
  --label__color: #222;

  transition: background-color 0.2s ease;
  width: 100%;
  font-family: Inter, serif;

  input {
    appearance: none;
    outline: none;
    width: 100%;
    margin: 10px 0;
    border-radius: 10px;
    border: 1.5px solid ${({ err }: Props) => (err ? `red` : `#c7c8cf`)};
    padding: 1.9rem 1rem 0.7rem;
    font-size: 18px;
    transition: border-color 0.2s ease;
    caret-color: var(--color__accent);
    color: var(--input__text--color);

    ${(p: Props) =>
      p.disabled
        ? `
        opacity: 0.4;
    background-color: #D8D8D8;`
        : ``};

    &:hover {
      border-color: var(--input__border--hover);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0);
    }
  }

  input:placeholder-shown + label::before {
    transform: translate3d(0, -2.2rem, 0) scale3d(1, 1, 1);
  }

  label::before,
  input:focus + label::before {
    transform: translate3d(0, -3.12rem, 0) scale3d(0.7, 0.7, 1);
  }

  input:focus + label::before,
  input:not(:placeholder-shown) + label::before {
    color: var(--color__accent);
    top: -25px;
  }

  label {
    display: block;
    position: relative;
    max-height: 0;
    font-weight: 500;
    pointer-events: none;

    &::before {
      color: var(--label__color);
      content: attr(data-content);
      display: inline-block;
      filter: blur(0);
      backface-visibility: hidden;
      transform-origin: left top;
      transition: transform 0.2s ease;
      width: 100%;
      padding-left: 10px;
      text-align: left;
      top: -22px;
      font-size: 1.5rem;
      position: relative;
    }
  }
`;

export function FormInput({
  disabled,
  errors,
  register,
  required,
  name,
  label,
  type,
  style,
  defaultValue,
  pattern,
  invalidMessage,
}: {
  errors: FieldErrors;
  disabled?: boolean;
  register: UseFormRegister<FieldValues>;
  name: string;
  label: string;
  type?: string;
  defaultValue?: string;
  required?: boolean;
  style?: any;
  pattern?: any;
  invalidMessage?: string;
}) {
  const options = {
    required: !!required,
  } as any;
  if (pattern) {
    options.pattern = {
      value: pattern,
      message: invalidMessage,
    };
  }
  return (
    <FloatingInput err={!!errors[name]} style={style} disabled={disabled}>
      <input
        id={`input__${name}`}
        data-testid={`${name}-input`}
        placeholder={label}
        defaultValue={defaultValue || ``}
        type={type || `text`}
        disabled={disabled}
        {...register(name, options)}
      />
      <label htmlFor={`input__${name}`} data-content={label} />
      {errors[name] && (
        <span>{invalidMessage || `This field is required`}</span>
      )}
    </FloatingInput>
  );
}
