import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CachePolicies, useFetch } from 'use-http';
import {
  AccountContext,
  AccountContextProps,
} from '@/features/account-context';
import { routes } from '@/webapi/routes';

type UserDetailsRequest = {
  firstName?: string;
  lastName?: string;
  phone?: string;
  profilePic?: string;
};

export function useUpdatePersonalInfo() {
  const ctx = useContext(AccountContext);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm();

  const { post, error, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: false,
    },
  }));
  const [image, setImage] = useState<string>(``);

  async function updateUserDetails(data: UserDetailsRequest) {
    await post(routes.updateUserDetails(), { ...data, profilePic: image });
    await ctx.fetchUserContext();
  }

  const hasChanges = isDirty || imageChanged(image, ctx);
  return {
    ctx,
    register,
    errors,
    error,
    loading,
    isDirty,
    reset,
    image,
    setImage,
    onSubmit: handleSubmit(updateUserDetails),
    hasChanges,
  };
}

function imageChanged(image: string, ctx: AccountContextProps) {
  return image !== ctx.account?.store?.profilePic;
}
