import React from 'react';
import { BsPerson } from 'react-icons/bs';
import styled from 'styled-components';

const InnerHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  user-select: none;
  font-size: 16px;
  font-weight: 600;
  padding: 0 0 2rem 0;
  border-bottom: 1px solid #f1f3f4;
  svg {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export function InnerHeader() {
  return (
    <InnerHeaderContainer>
      <BsPerson size={20} />
      My Details
    </InnerHeaderContainer>
  );
}
